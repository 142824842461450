import React from "react"

import { Link } from "gatsby"

import Layoutcs from "../../../components/layoutcs/layoutcs"
import SEO from "../../../components/seo"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Bloglist from "../../../components/bloglist/bloglist"

import Footer from "../../../components/footer/footer"

import "../../index.scss"
import "../../blog.scss"

import ImgBlogPost from "../../../images/blogpost/modernizace-php-aplikaci-1.jpg"

import ImgBlog11 from "../../../images/blog/modernizace-php-aplikaci-1.jpg"
import ImgBlog12 from "../../../images/blog/modernizace-php-aplikaci-2.jpg"
import ImgBlog13 from "../../../images/blog/modernizace-php-aplikaci-3.jpg"

const Blog = () => (

	<Layoutcs>
		<SEO title="Modernizace PHP aplikací 1 - historie PHP" description="Připravili jsme pro vás blog seriál o upgrade PHP aplikací." />

		<Container className="ftrs-content pb-4 ftrs-blogpost">

			<h1 className="ftrs-h1">Modernizace PHP aplikací&nbsp;1 - historie&nbsp;PHP</h1>
			<div className="ftrs-blogpost__date">21.07.2023</div>

			<img className="ftrs-blogpost__img" src={ImgBlogPost} alt="Modernizace PHP aplikací 1 - historie PHP" />

		</Container>

		<div className="ftrs-blogpost__text">
			<Container>

				<p>Připravili jsme pro vás seriál <strong>Modernizace PHP aplikací</strong>. V&nbsp;tomto seriálu vám chceme přiblížit, jak významný může být pro&nbsp;vaše podnikání upgrade vaší webové aplikace, abyste byli vždy v&nbsp;souladu s&nbsp;nejnovějšími technologickými trendy, zajistili si bezpečnost a&nbsp;rychlost a&nbsp;umožnili rozvoj a&nbsp;provoz webové aplikace i&nbsp;do&nbsp;budoucna.</p>

				<Row className="mt3">

					<Col xs="12" md="6" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<Link className="ftrs-blog__item ftrs-blog__item--white-bg ftrs-blog__item--tiny" activeClassName="ftrs-blog__item--active" to="/cs/blog/modernizace-php-aplikaci-1-historie-php">
							<div className="ftrs-blog__img-cover ftrs-blog__img-cover--white-bg">
								<img className="ftrs-blog__img" src={ImgBlog11} alt="Modernizace PHP aplikací 1 - historie PHP" />
							</div>
							<div className="ftrs-blog__date">21.07.2023</div>
							<h2 className="ftrs-blog__title ftrs-h3">Modernizace PHP aplikací&nbsp;1 - historie&nbsp;PHP</h2>
						</Link>
					</Col>

					<Col xs="12" md="6" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<Link className="ftrs-blog__item ftrs-blog__item--white-bg ftrs-blog__item--tiny" activeClassName="ftrs-blog__item--active" to="/cs/blog/modernizace-php-aplikaci-2-duvody-k-aktualizaci">
							<div className="ftrs-blog__img-cover ftrs-blog__img-cover--white-bg">
								<img className="ftrs-blog__img" src={ImgBlog12} alt="Modernizace PHP aplikací 2 - důvody k aktualizaci" />
							</div>
							<div className="ftrs-blog__date">30.08.2023</div>
							<h2 className="ftrs-blog__title ftrs-h3">Modernizace PHP aplikací&nbsp;2 - důvody k&nbsp;aktualizaci</h2>
						</Link>
					</Col>

					<Col xs="12" md="6" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<Link className="ftrs-blog__item ftrs-blog__item--white-bg ftrs-blog__item--tiny" activeClassName="ftrs-blog__item--active" to="/cs/blog/modernizace-php-aplikaci-3-posouzeni-php-projektu">
							<div className="ftrs-blog__img-cover ftrs-blog__img-cover--white-bg">
								<img className="ftrs-blog__img" src={ImgBlog13} alt="Modernizace PHP aplikací 3 - posouzení PHP projektu" />
							</div>
							<div className="ftrs-blog__date">16.09.2023</div>
							<h2 className="ftrs-blog__title ftrs-h3">Modernizace PHP aplikací&nbsp;3 - posouzení php&nbsp;projektu</h2>
						</Link>
					</Col>

				</Row>

				<hr className="mt0 mb2" />

				<h2 className="ftrs-h2 mt2 mb1">PHP je skriptovací programovací jazyk primárně navržený pro&nbsp;vývoj webových aplikací.</h2>
				<p>PHP vyvinul Rasmus Lerdorf již v&nbsp;roce 1993 a&nbsp;první jeho verze byla spuštěna v&nbsp;roce 1995. Od&nbsp;té doby se těší obrovské oblibě a&nbsp;řadí se mezi <strong>nejoblíbenější jazyky pro&nbsp;tvorbu interaktivních webových aplikací</strong>.</p>

				<p>PHP běží na&nbsp;serveru a&nbsp;zpracovává zde požadavky - k&nbsp;uživateli je posílán až výsledek jeho činnosti. PHP je možné volat pomocí příkazového řádku, dotazovacích metod HTTP nebo pomocí webových služeb.</p>

				<p className="mb-3">PHP podporuje mnoho knihoven pro&nbsp;různé účely - např. zpracování textu, grafiky, práci se&nbsp;soubory, přístup k&nbsp;většině databázových systémů (mj. MySQL, ODBC, Oracle, PostgreSQL, MSSQL, SQLite), podporu celé řady internetových protokolů (HTTP, SMTP, SNMP, FTP, IMAP, POP3, LDAP,&nbsp;…).</p>

				<table className="mb-3">
					<col width="100" />
					<col />
					<tr>
						<td><strong>PHP/FI</strong></td><td>První verze z&nbsp;roku 1995.</td>
					</tr>
					<tr>
						<td><strong>PHP 3</strong></td><td>Vylepšená verze z&nbsp;roku 1998.</td>
					</tr>
					<tr>
						<td><strong>PHP 4</strong></td><td>Uvedena v roce 2000 s&nbsp;větším důrazem na&nbsp;efektivitu.</td>
					</tr>
					<tr>
						<td><strong>PHP 5</strong></td><td>Z roku 2004, přinesla mnoho nových objektově orientovaných funkcí.</td>
					</tr>
					<tr>
						<td><strong>PHP 7</strong></td><td>Velký skok v rychlosti a&nbsp;efektivitě od&nbsp;roku 2015.</td>
					</tr>
					<tr>
						<td><strong>PHP 8</strong></td><td>Nejnovější verze z&nbsp;roku 2020, která přinesla mnoho nových funkcionalit a&nbsp;optimalizací.</td>
					</tr>
				</table>

				<p>Ačkoliv je PHP&nbsp;8 aktuální verzí, je důležité stále sledovat <a className="ftrs-blacklink" href="https://www.php.net" target="_blank" rel="noreferrer">oficiální PHP stránky</a> pro&nbsp;informace o&nbsp;nových verzích a&nbsp;aktualizacích.</p>

				<hr className="mt2 mb2" />

				<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 mt3 mb2">Správa PHP aplikací od&nbsp;Future Rockstars</h2>

				<Row>
					<Col lg="6">
						<p data-sal="fade" data-sal-duration="1000" className="ftrs-services__header-text">
							Svěřte nám svou starší aplikaci - pomůžeme Vám s&nbsp;analýzou a&nbsp;realizací jejího upgrade. Máme mnohaleté zkušenosti s&nbsp;údržbou a&nbsp;rozvojem PHP aplikací. Daří se nám ze&nbsp;zastaralého kódu vytvářet moderní, udržitelné a&nbsp;bezpečné aplikace, které dobře vypadají a&nbsp;mohou se bez&nbsp;problémů rozšířit o&nbsp;nové funkcionality.
						</p>
					</Col>
					<Col lg="3">
						<ul data-sal="fade" data-sal-duration="1000" className="mb2">
							<li>analýza</li>
							<li>update technologie</li>
							<li>bezpečnost</li>
							<li>výkon a&nbsp;stabilita</li>
							<li>škálování</li>
						</ul>
					</Col>
					<Col lg="3">
						<ul data-sal="fade" data-sal-duration="1000" className="mb2">
							<li>nové funkce</li>
							<li>modernizace designu</li>
							<li>automatizace</li>
							<li>napojení na&nbsp;další systémy</li>
						</ul>
					</Col>
				</Row>

			</Container>
		</div>

		<Footer lang='cs'></Footer>

		<Container className="pt-4 pb-4">
			<Bloglist></Bloglist>
		</Container>

	</Layoutcs>

)

export default Blog